var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticClass: "modal-warning",
      attrs: {
        id: "ocr-issue-modal",
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(1, "Warning"),
        "ok-variant": "light",
        "header-bg-variant": "warning",
        "header-border-variant": "warning",
        size: "md",
        "ok-only": "",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
      },
      on: {
        ok: function ($event) {
          return _vm.$emit("expenses-warning:ok")
        },
      },
      model: {
        value: _vm.isModalOpen,
        callback: function ($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen",
      },
    },
    [
      _c("div", [
        _vm._v(
          "\n\t\t" +
            _vm._s(
              _vm.ocrMessageError !== ""
                ? _vm.ocrMessageError
                : _vm.FormMSG(
                    2,
                    "An error has occurred during created the expense, please retry again or contact your administrator."
                  )
            ) +
            "\n\t"
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }