<template>
	<!-- modal warning if ticket is duplicated-->
	<b-modal
		id="ocr-issue-modal"
		header-class="header-class-modal-doc-package"
		:title="FormMSG(1, 'Warning')"
		class="modal-warning"
		v-model="isModalOpen"
		@ok="$emit('expenses-warning:ok')"
		ok-variant="light"
		header-bg-variant="warning"
		header-border-variant="warning"
		size="md"
		ok-only
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
	>
		<div>
			{{
				ocrMessageError !== ''
					? ocrMessageError
					: FormMSG(2, 'An error has occurred during created the expense, please retry again or contact your administrator.')
			}}
		</div>
	</b-modal>
	<!-- modal warning if ticket is duplicated-->
</template>

<script>
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'DuplicateExpensesWarningModal',
	props: ['value', 'ocrMessageError'],
	mixins: [languageMessages],
	computed: {
		isModalOpen: {
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.value;
			},
			/**
			 * @param {!Boolean} status
			 */
			set(status) {
				this.$emit('input', status);
			}
		}
	}
};
</script>
